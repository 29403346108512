import { useCallback } from 'react';
import { useApiCall, usePreferencesContext } from '@jarvis/react-portal-addons';
import { ApiCallResponseType } from 'src/types/commonTypes';
import { apiCallback } from './baseAPICall';

export type SupportStepsType = {
  setup_step: {
    support_text: string;
    support_graphic: { url: string };
    support_graphic_alt?: { url: string };
    support_graphic_title: string;
  };
}[];

export type UseDeviceSetupResponseType = {
  data: {
    entry: {
      support_headline: string;
      support_subhead: string;
      change_printer_button_text: string;
      continue_button_text: string;
      support_steps: SupportStepsType;
    };
  } | null;
} & ApiCallResponseType;

export const useDeviceSetup = (): UseDeviceSetupResponseType => {
  const config = usePreferencesContext();

  const apiCall = useCallback(
    async ({ entryId }: { entryId: string }) => {
      const apiName = 'device-setup';
      return await apiCallback({ entryId, apiName, config });
    },
    [config]
  );

  return useApiCall({ apiCall, init: false });
};
