import { breakpoints } from 'src/constants/breakpoints';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  width: 75%;
  margin: 0 auto;
  padding-top: 1.75rem;

  @media screen and (min-width: ${breakpoints.tablet}) and (max-width: ${breakpoints.wide}) {
    padding-top: 4rem;
  }

  @media only screen and (min-width: ${breakpoints.desktop}) and (-ms-high-contrast: active),
    (-ms-high-contrast: none) {
    margin-bottom: 0;
  }
`;

export const Brand = styled.div`
  display: flex;
  align-items: center;
`;

export const TextLogo = styled.h5`
  margin-left: 0.5rem;
  font-weight: 500;
`;

export const Title = styled.h1`
  color: #4e4e4e;
  font-weight: 500;
  padding-top: 1rem;
  text-align: center;

  @media only screen and (min-width: ${breakpoints.tablet}) {
    padding-top: 5rem;
  }
`;

export const SubTitle = styled.h2`
  color: #4e4e4e;
  font-weight: 400;
  text-align: center;
  padding-top: 1rem;

  @media only screen and (min-width: ${breakpoints.tablet}) {
    padding-top: 2rem;
  }
`;

export const ContainerButton = styled.div`
  display: flex;
  padding-bottom: 1rem;
  justify-content: center;
  padding-top: 1rem;

  @media only screen and (min-width: ${breakpoints.tablet}) {
    padding-top: 4rem;
    width: 100%;
  }
`;

export const ContainerAppBadges = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 1rem;
  gap: 1rem;
  flex-wrap: wrap;

  .microsoft {
    width: 6.75rem;
  }

  @media only screen and (max-width: ${breakpoints.smalldesktop}) {
    flex-direction: column;
    align-items: center;
  }
`;
