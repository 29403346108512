import React from 'react';
import { LoaderWidget } from '@jarvis/react-portal-addons';
import * as S from './styles';

export const LoadingPage = (): React.ReactElement => {
  return (
    <S.Container>
      <LoaderWidget dataTestId="loading-page-progress-indicator" />
    </S.Container>
  );
};
