import styled from 'styled-components';
import { breakpoints } from '../../constants/breakpoints';

export const Container = styled.div<{ isHpx: boolean }>`
  min-height: 100vh;
  padding: 34px 32px 0 32px;
  box-sizing: border-box;

  @media only screen and (max-width: ${breakpoints.smalldesktop}) {
    padding: 24px 24px 0 24px;

    ${({ isHpx }) =>
      isHpx &&
      `
      display: flex;
      flex-direction: column;
  `}
  }
`;

export const Title = styled.h4`
  @media only screen and (max-width: ${breakpoints.bigtablet}) {
    text-align: center;
  }
`;

export const SubTitle = styled.h2`
  @media only screen and (max-width: ${breakpoints.bigtablet}) {
    text-align: center;
  }
`;

export const SupportText = styled.div`
  flex-shrink: 2;
  padding: 1rem 0;
  margin-bottom: 1.5rem;
`;

export const StepContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: ${breakpoints.bigtablet}) {
    flex-direction: column-reverse;

    > .slider-container {
      padding: 0 !important;
    }
  }
`;
