import React from 'react';
import * as S from './styles';
import DeviceList from 'src/components/DeviceList';
import { DependencyManagerProvider } from 'src/contexts/dependencyManager';
import * as T from './types';
import * as C from 'src/types/commonTypes';
import { Router, Route, Switch } from 'react-router-dom';
import DeviceSetup from 'src/components/DeviceSetup';
import GenericSetup from 'src/components/GenericSetup';
import DeviceSelectList from 'src/components/DeviceSelectList';
import { isSupported } from 'src/utils/printerSupport';
import MultiUiBrandProvider from 'src/contexts/MultiUiBrand/MultiUiBrandProvider';
import { UiTheme, DefaultPreferences } from 'src/config/constants';
import ThemeProvider from 'src/components/ThemeProvider';
import { PreferencesProvider } from '@jarvis/react-portal-addons';
import { createBrowserHistory } from 'history';
import { DEEEPLINKS } from 'src/constants/deeplinks';
import { DirectionProvider } from '@veneer/core';
import UpgradePage from 'src/components/UpgradePage';

export const AppComponent: React.FC<T.AppPropsType> = (props) => {
  // get querystring parameters
  const searchParams = new URLSearchParams(window.location.search);
  const deviceId = searchParams.get('deviceid');
  const platform = searchParams.get('platform') || 'windows';

  // parses the deviceid parameter (a list of printers in bas64) and
  // transforms it into a list of supported devices
  // in case of error goes to the generic setup
  let devices: C.DeviceListType = null;
  if (deviceId) {
    let data: C.PrinterListType = null;
    try {
      data = JSON.parse(atob(decodeURIComponent(deviceId)));
      devices = data
        .filter(
          (item, index, array) =>
            array.findIndex((a) => a.modelName === item.modelName) === index
        )
        .map((item) => {
          let model = item?.links?.[0]?.href?.split('/')?.pop()?.toUpperCase();
          if (model?.includes('?')) {
            model = model?.split('?')?.[0];
          }
          if (isSupported(model)) {
            const device: C.ListedDeviceType = {
              href: `${DEEEPLINKS.SUPPORT_PRINTER_CONNECTION}/` + model,
              modelName: item.modelName || 'HP Generic Printer'
            };
            return device;
          }
          return null;
        })
        .filter((item) => item !== null);
    } catch (error) {
      console.error('Error parsing printer list:', error);
      window.Shell?.v1?.navigation?.push(
        `${DEEEPLINKS.SUPPORT_GENERIC}?platform=${platform}`
      );
    }
  }

  // routes to the correct page
  return (
    <S.Container>
      <Switch>
        <Route path={DEEEPLINKS.SUPPORT_LIST}>
          <DeviceList
            {...props}
            devices={devices}
          />
        </Route>
        <Route path={`${DEEEPLINKS.SUPPORT_PRINTER_CONNECTION}/:model?`}>
          <DeviceSetup
            {...props}
            devices={devices}
            deviceId={deviceId}
            platform={platform}
          />
        </Route>
        <Route path={DEEEPLINKS.SUPPORT_GENERIC}>
          <GenericSetup {...props} />
        </Route>
        <Route path={DEEEPLINKS.SUPPORT_PRINTER_LIST}>
          <DeviceSelectList {...props} />
        </Route>
        <Route path={DEEEPLINKS.UPGRADE}>
          <UpgradePage {...props} />
        </Route>
      </Switch>
    </S.Container>
  );
};

// This component wraps your main component with the DependencyManagerProvider
// This provider is responsible to manage global dependencies to your component
const App: React.FC<T.AppPropsType> = (props) => {
  // temporary solution to change the theme: querystring ?theme=hpx
  const searchParams = new URLSearchParams(window.location.search);
  let uiBrand = UiTheme.hpsmart;
  if (searchParams.get('theme') === 'hpx') {
    uiBrand = UiTheme.hpx;
  }
  const history = window.Shell?.v1?.navigation || createBrowserHistory();
  const { properties } = props;
  return (
    <DependencyManagerProvider>
      <MultiUiBrandProvider uiBrand={uiBrand}>
        <ThemeProvider>
          <PreferencesProvider
            currentPreferences={properties}
            defaultPreferences={DefaultPreferences}
          >
            <Router history={history}>
              <DirectionProvider>
                <AppComponent {...props} />
              </DirectionProvider>
            </Router>
          </PreferencesProvider>
        </ThemeProvider>
      </MultiUiBrandProvider>
    </DependencyManagerProvider>
  );
};

export default App;
