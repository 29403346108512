import styled from 'styled-components';

export const Container = styled.div``;

export const Img = styled.img`
  max-width: 100%;
  max-height: 100%;
  height: 80px;
  width: 80px;
`;
