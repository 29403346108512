import { useCallback } from 'react';
import { useApiCall, usePreferencesContext } from '@jarvis/react-portal-addons';
import { apiCallback } from './baseAPICall';
import { ApiCallResponseType } from 'src/types/commonTypes';

type UpgradePageType = {
  data: {
    entries: {
      hpsmart_heading: string;
      upgrade_headline: string;
      upgrade_os_message: string;
      button_text: string;
      button_destination: string;
    }[];
  } | null;
} & ApiCallResponseType;

export default function useUpgradePage(): UpgradePageType {
  const config = usePreferencesContext();

  const apiCall = useCallback(async () => {
    const apiName = 'upgrade';
    return await apiCallback({ apiName, config });
  }, [config]);

  return useApiCall({ apiCall });
}
