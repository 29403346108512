import React, { useContext } from 'react';
import useGeneric from 'src/hooks/useGeneric';
import { LoadingPage } from '../LoadingPage';
import * as S from './styles';
import * as V from '@veneer/core';
import useMultiUiBrandContext from 'src/contexts/MultiUiBrand/useMultiUiBrandContext';

const GenericSetup: React.FC = () => {
  const { uiBrand } = useMultiUiBrandContext();
  const { data, pending } = useGeneric();
  const useDirectionContext = () => useContext(V.DirectionContext);
  const { isRTL } = useDirectionContext();
  const {
    generic_buttons,
    support_body,
    support_graphic,
    support_graphic_alt,
    support_headline
  } = data?.entries[0] || {};

  if (pending) {
    return <LoadingPage />;
  }

  const useAltGraphic = uiBrand === 'hpx' && support_graphic_alt;
  const graphic = useAltGraphic ? support_graphic_alt : support_graphic;
  const graphicClassName = useAltGraphic ? '' : uiBrand;
  return (
    <S.Container>
      <S.Title
        data-testid="generic-page-title"
        className="subtitle-large"
        dangerouslySetInnerHTML={{ __html: support_headline }}
        isRTL={isRTL}
      />

      <S.ContainerGraphic>
        <img
          id="graphic"
          data-testid="generic-page-printer-image"
          src={graphic?.url}
          alt={graphic?.title}
          className={graphicClassName}
        />
        <S.ContainerBody
          data-testid="generic-page-body-text"
          className={`label_regular ${uiBrand}`}
          dangerouslySetInnerHTML={{ __html: support_body }}
        />
      </S.ContainerGraphic>

      <S.ContainerButton
        className={uiBrand}
        isRTL={isRTL}
      >
        <V.Button
          id="generic-support-close"
          data-testid="generic-page-exit-button"
          appearance="ghost"
          className={uiBrand}
          onClick={async () => window.location.assign('hpsmart://reset/back')}
        >
          {generic_buttons[0]?.buttons_group.tertiary_button_text}
        </V.Button>

        <S.GroupButton className={uiBrand}>
          <V.Button
            id="hp-support"
            data-testid="generic-page-support-button"
            appearance="secondary"
            className={uiBrand}
            onClick={() => {
              window.open(
                'https://support.hp.com/go/document/ish_3967769-3956733-16',
                '_blank',
                'noopener,noreferrer'
              );
            }}
          >
            {generic_buttons[0]?.buttons_group?.secondary_button_text}
          </V.Button>
          <V.Button
            id="find-all-printers"
            data-testid="generic-page-find-my-printer-button"
            className={uiBrand}
            onClick={async () => {
              window.location.assign('hpsmart://reset/continue');
            }}
          >
            {generic_buttons[0]?.buttons_group?.primary_button_text}
          </V.Button>
        </S.GroupButton>
      </S.ContainerButton>
    </S.Container>
  );
};

export default GenericSetup;
