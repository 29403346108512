export const breakpoints = {
  xSmall: '18.75rem', // 300px
  small: '25.8125rem', // 413px
  mobile: '25.875rem', // 414px
  bigmobile: '28.125rem', // 450px
  smalldesktop: '31.25rem', // 500px
  desktop: '33.75rem', // 540px
  tablet: '40rem', // 640px
  bigtablet: '45rem', // 720px
  medium: '55rem', //880px
  wide: '80rem', // 1280px
  large: '100rem', // 1600px
  immense: '120rem', // 1920px
  immensewide: '160rem' // 4K
};
