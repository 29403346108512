import React from 'react';
import Carousel from 'nuka-carousel';
import { SupportStepsType } from 'src/hooks/useDeviceSetup';
import { supportImages } from 'src/assets/support';
import * as S from './styles';

export const CustomCarousel = ({
  currentIndex,
  handleRightClick,
  handleLeftClick,
  support_steps = [],
  isHpx,
  isRTL
}: {
  currentIndex: number;
  handleRightClick: (func?: () => void) => void;
  handleLeftClick: (func?: () => void) => void;
  support_steps: SupportStepsType;
  isHpx: boolean;
  isRTL: boolean;
}): React.ReactElement => {
  const isLastSlide = (currentSlide: number, slideCount: number) =>
    currentSlide === slideCount - 1;

  const isFirstSlide = (currentSlide: number) => currentSlide === 0;

  const LeftIcon = isHpx
    ? S.CustomIconChevronLeftCircle
    : S.CustomIconChevronLeft;

  const RightIcon = isHpx
    ? S.CustomIconChevronRightCircle
    : S.CustomIconChevronRight;

  const IconSize = isHpx ? 48 : 36;

  function transformSupportText(support_text: string): string {
    return support_text.replace(/src="([^"]+)"/g, (match, path) => {
      return `src=${supportImages[path] || path}`;
    });
  }

  return (
    <S.CarouselContainer>
      <Carousel
        slideIndex={currentIndex}
        swiping={false}
        dragging={false}
        defaultControlsConfig={{
          pagingDotsStyle: {
            display: 'none'
          }
        }}
        renderCenterLeftControls={({
          nextSlide,
          previousSlide,
          currentSlide,
          slideCount
        }) => (
          <S.ControlsContainer>
            <LeftIcon
              id="chev-left"
              data-testid="printer-connection-left-icon"
              color="colorHpBlue6"
              size={IconSize}
              filled
              onClick={() => {
                if (isRTL && !isLastSlide(currentSlide, slideCount)) {
                  return handleRightClick(nextSlide);
                }

                if (!isRTL && !isFirstSlide(currentSlide)) {
                  return handleLeftClick(previousSlide);
                }
              }}
              disabled={
                isRTL
                  ? isLastSlide(currentSlide, slideCount)
                  : isFirstSlide(currentSlide)
              }
            />
          </S.ControlsContainer>
        )}
        renderCenterRightControls={({
          nextSlide,
          previousSlide,
          currentSlide,
          slideCount
        }) => (
          <S.ControlsContainer>
            <RightIcon
              id="chev-right"
              data-testid="printer-connection-right-icon"
              color="colorHpBlue6"
              size={IconSize}
              filled
              onClick={() => {
                if (isRTL && !isFirstSlide(currentSlide)) {
                  return handleLeftClick(previousSlide);
                }

                if (!isRTL && !isLastSlide(currentSlide, slideCount)) {
                  return handleRightClick(nextSlide);
                }
              }}
              disabled={
                isRTL
                  ? isFirstSlide(currentSlide)
                  : isLastSlide(currentSlide, slideCount)
              }
            />
          </S.ControlsContainer>
        )}
      >
        {support_steps.map((step, id) => {
          const { setup_step } = step;
          const {
            support_graphic,
            support_graphic_alt,
            support_graphic_title,
            support_text
          } = setup_step;

          const useHpxFilter = isHpx && !support_graphic_alt;
          const graphic =
            isHpx && support_graphic_alt
              ? support_graphic_alt
              : support_graphic;

          const { url } = graphic;

          return (
            <S.CarouselItems
              key={id}
              isRTL={isRTL}
              data-testid="printer-connection-carousel-item"
            >
              <S.Graphic
                id="graphic"
                data-testid="printer-connection-step-image"
                src={url}
                alt={support_graphic_title}
                isRTL={isRTL}
                isHpx={useHpxFilter}
              />
              <S.Content
                data-testid="printer-connection-step-text"
                className="body"
                dangerouslySetInnerHTML={{
                  __html: transformSupportText(support_text)
                }}
              />
            </S.CarouselItems>
          );
        })}
      </Carousel>
    </S.CarouselContainer>
  );
};
