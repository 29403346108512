export const supportImages: Record<string, string> = {
  '/assets/support/3m_step_1.svg': require('./3m_step_1.svg'),
  '/assets/support/3m_step_2.svg': require('./3m_step_2.svg'),
  '/assets/support/3m_step_3.svg': require('./3m_step_3.svg'),
  '/assets/support/3m_step_4.svg': require('./3m_step_4.svg'),
  '/assets/support/4_3_reset.svg': require('./4_3_reset.svg'),
  '/assets/support/close_icon.svg': require('./close_icon.svg'),
  '/assets/support/dial_low_reset.svg': require('./dial_low_reset.svg'),
  '/assets/support/eyrie_step_1.svg': require('./eyrie_step_1.svg'),
  '/assets/support/eyrie_step_2.svg': require('./eyrie_step_2.svg'),
  '/assets/support/eyrie_step_3.svg': require('./eyrie_step_3.svg'),
  '/assets/support/eyrie_step_4.svg': require('./eyrie_step_4.svg'),
  '/assets/support/gear_icon.svg': require('./gear_icon.svg'),
  '/assets/support/generic_printer.svg': require('./generic_printer.svg'),
  '/assets/support/generic_printer_hpx.svg': require('./generic_printer_hpx.svg'),
  '/assets/support/info_icon.svg': require('./info_icon.svg'),
  '/assets/support/power_cycle_reset.svg': require('./power_cycle_reset.svg'),
  '/assets/support/printer_icon.svg': require('./printer_icon.svg'),
  '/assets/support/question_icon.svg': require('./question_icon.svg'),
  '/assets/support/skyreach_adf_step_1.svg': require('./skyreach_adf_step_1.svg'),
  '/assets/support/skyreach_adf_step_2.svg': require('./skyreach_adf_step_2.svg'),
  '/assets/support/skyreach_adf_step_3.svg': require('./skyreach_adf_step_3.svg'),
  '/assets/support/skyreach_adf_step_4.svg': require('./skyreach_adf_step_4.svg'),
  '/assets/support/skyreach_step_1.svg': require('./skyreach_step_1.svg'),
  '/assets/support/skyreach_step_2.svg': require('./skyreach_step_2.svg'),
  '/assets/support/skyreach_step_3.svg': require('./skyreach_step_3.svg'),
  '/assets/support/skyreach_step_4.svg': require('./skyreach_step_4.svg'),
  '/assets/support/taccola_adf_step_1.svg': require('./taccola_adf_step_1.svg'),
  '/assets/support/taccola_adf_step_2.svg': require('./taccola_adf_step_2.svg'),
  '/assets/support/taccola_fb_step_1.svg': require('./taccola_fb_step_1.svg'),
  '/assets/support/taccola_fb_step_2.svg': require('./taccola_fb_step_2.svg'),
  '/assets/support/vasari_adf_step_1.svg': require('./vasari_adf_step_1.svg'),
  '/assets/support/vasari_adf_step_2.svg': require('./vasari_adf_step_2.svg'),
  '/assets/support/vasari_fb_step_1.svg': require('./vasari_fb_step_1.svg'),
  '/assets/support/vasari_fb_step_2.svg': require('./vasari_fb_step_2.svg'),
  '/assets/support/wi_fi_icon.svg': require('./wi_fi_icon.svg')
};
