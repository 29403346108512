import styled from 'styled-components';
import primitives from '@veneer/primitives';
import { breakpoints } from '../../../constants/breakpoints';
import {
  IconChevronLeft,
  IconChevronRight,
  IconChevronLeftCircle,
  IconChevronRightCircle
} from '@veneer/core';

export const CarouselContainer = styled.div`
  direction: ltr !important;
`;

export const ControlsContainer = styled.div`
  @media only screen and (max-width: ${breakpoints.bigtablet}) {
    display: none;
  }
`;

export const CustomIconChevronLeft = styled(IconChevronLeft)`
  cursor: pointer;
`;

export const CustomIconChevronLeftCircle = styled(IconChevronLeftCircle)`
  cursor: pointer;
  fill: ${primitives.color.royalBlue7} !important;
`;

export const CustomIconChevronRight = styled(IconChevronRight)`
  cursor: pointer;
`;

export const CustomIconChevronRightCircle = styled(IconChevronRightCircle)`
  cursor: pointer;
  fill: ${primitives.color.royalBlue7} !important;
`;

export const CarouselItems = styled.div<{ isRTL: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
  user-select: none;
  outline: none;
  padding: 0 60px;
  direction: ${(props) => (props.isRTL ? 'rtl' : 'ltr')} !important;

  @media only screen and (max-width: ${breakpoints.bigtablet}) {
    flex-direction: column-reverse;
    padding: 0;
  }
`;

export const Graphic = styled.img<{ isRTL: boolean; isHpx: boolean }>`
  ${(props) => (props.isRTL ? 'margin-left: 80px' : 'margin-right: 80px')};

  ${(props) => props.isHpx && `filter: hue-rotate(46deg)`};

  @media screen and (max-width: ${breakpoints.medium}) {
    ${(props) => (props.isRTL ? 'margin-left: 40px' : 'margin-right: 40px')};
  }

  @media only screen and (max-width: ${breakpoints.bigtablet}) {
    ${(props) => (props.isRTL ? 'margin-left: 0px' : 'margin-right: 0px')};
  }
`;

export const Content = styled.div`
  text-align: justify;
  max-width: 500px;

  @media only screen and (max-width: ${breakpoints.bigtablet}) {
    text-align: center;
    margin: 48px 0 60px 0;
  }
`;
