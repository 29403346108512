import React, { useMemo } from 'react';
import MultiUiBrandContext from './MultiUiBrandContext';
import { UiTheme } from 'src/config/constants';
import { MultiUiBrandProviderProps } from './type';

const MultiUiBrandProvider = ({
  uiBrand = UiTheme.hpsmart,
  ...props
}: MultiUiBrandProviderProps): React.ReactElement => {
  const state = useMemo(() => {
    return {
      uiBrand
    };
  }, [uiBrand]);

  return (
    <MultiUiBrandContext.Provider value={state}>
      {props.children}
    </MultiUiBrandContext.Provider>
  );
};

export default MultiUiBrandProvider;
