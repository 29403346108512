const _3M_PRINTERS = {
  models: ['3UC58D'],
  entryId: 'blt17852eefeb567454'
};

const TACCOLA_FB_PRINTERS = {
  models: ['25R66A'],
  entryId: 'bltbff2a91204f4f7b1'
};

const TACCOLA_ADF_PRINTERS = {
  models: ['4WS37B'],
  entryId: 'bltfc1debe2f44be33d'
};

const VASARI_FB_PRINTERS = {
  models: ['5SE22B'],
  entryId: 'bltc834475d35549749'
};

const VASARI_ADF_PRINTERS = {
  models: ['6WD15A'],
  entryId: 'blte244c749a6e94457'
};

const SKYREACH_FB_PRINTERS = {
  models: ['6GX04A'],
  entryId: 'blte371deb4f601da7e'
};

const SKYREACH_ADF_PRINTERS = {
  models: ['6GX05A'],
  entryId: 'blt6f725c20afa36a3f'
};

const EYRIE_PRINTERS = {
  models: ['6GW64A'],
  entryId: 'bltd1d400899c00ee51'
};

const BEAM_PRINTERS = {
  models: ['2Y9H0A'],
  entryId: 'blt943033e3c848127b'
};

const JUPITER_PRINTERS = {
  models: ['7QR88A'],
  entryId: 'blt7758a18f4171b9c5'
};

const BEAM_MFP_PRINTERS = {
  models: ['2Y9H2A'],
  entryId: 'bltaa8e0ea01053683f'
};

const PIXIU_PRINTERS = {
  models: ['2Y9H4A'],
  entryId: 'bltbc6e5d0e142c685a'
};

const TASSEL_PRINTERS = {
  models: ['60K57D'],
  entryId: 'bltb34e4a1771e054f8'
};

const LEBI_PRINTERS = {
  models: ['3D4L3A'],
  entryId: 'blt8f077a041a1bbc0d'
};

const MARCONI_PRINTERS = {
  models: ['403X0A'],
  entryId: 'blt4b75cdb0264940ef'
};

const MORETO_PRINTERS = {
  models: ['405W1C'],
  entryId: 'bltf3cc542b93d0d966'
};

const VICTORIA_PRINTERS = {
  models: ['714L5A'],
  entryId: 'blt6bdd7b023a55923b'
};

const KEBIN_PRINTERS = {
  models: ['537P6A'],
  entryId: 'blt0be4b42bfa2e4fc8'
};

const DRAGONFLY_BASE_PRINTERS = {
  models: ['53X74A'],
  entryId: 'blt292fb20f6c07844a'
};

const DRAGONFLY_WL_PRINTERS = {
  models: ['53X75A'],
  entryId: 'blt2447ecc7a8f76ffe'
};

const DRAGONFLY_ADF_PRINTERS = {
  models: ['53X76A'],
  entryId: 'bltcbf96840ea259209'
};

const TASSEL_BASE_PRINTERS = {
  models: ['51P94A'],
  entryId: 'bltb34e4a1771e054f8'
};

const TRILLIUM_PRINTERS = {
  models: [
    'A24JGA',
    'AJ4Y5A',
    'AJ4Y4A',
    'A24J2C',
    'B18K0C',
    'B3ZG8C',
    'A24J6B',
    'A24J8B',
    'A24JCB',
    'A24JFB',
    '399P2B',
    'AJ4X0B',
    'A24J7B',
    'AJ4W4B',
    '399P1A',
    '89G05C',
    'AJ4W7B',
    'AJ4W8B',
    'AJ4W9B',
    'A24HSA',
    'A24HTA',
    'AJ4Y0A',
    'A24JKB',
    'A24HPB',
    'A24HMB',
    'A24HNB',
    'AJ4X9B',
    'A24JLC',
    'AJ4X7C',
    'B3ZF3A',
    'A24HFA',
    'A24HGA',
    'A24HHA',
    'A24HJA',
    'A24HKA',
    '89F97B',
    '89F98B',
    'A24J4B',
    'A24HWB',
    '89F99B',
    'A24HVB',
    '38Q66C',
    '399P0C',
    '4D047C',
    '89F86C',
    '89F87C',
    '89G03C',
    'A24HXC',
    'A24HYC',
    'A24J1C',
    'AJ4V7C',
    'A24JJC',
    'AJ4V8C',
    'AJ4W3C',
    '89F94A',
    'AJ4X1A',
    '89F88A',
    '89F89A',
    '89F91A',
    '89F93A',
    'AJ4V9A',
    'AJ4W1A',
    'AJ4W2A',
    'AJ4X2A',
    '89F95A',
    'A24J9A',
    'AJ4X3A',
    'B3ZF5A'
  ],
  entryId: 'blt845401b5fd66bc44'
};

const SELENE_PRINTERS = {
  models: [
    '4RA80A',
    '4RA81A',
    '4RA82A',
    '4RA83A',
    '4RA84A',
    '5HH64A',
    '5HH65A',
    '5HH66A',
    '5HH67A',
    '5HH68A',
    '5HH69A',
    '5HH70A',
    '5HH71A',
    '5HH72A',
    '5HH73A',
    '5HH74A',
    '5HH75A',
    '5HH76A'
  ],
  entryId: 'blt8eba40fe95941ea0'
};

const HULK_PRINTERS = {
  models: [
    '3G627A',
    '3G628A',
    '3G629A',
    '3G630A',
    '3G631A',
    '3G632A',
    '3G633A',
    '3G634A',
    '3G635A',
    '3G636A',
    '3G637A',
    '3G638A',
    '3G639A',
    '3G640A',
    '3G641A',
    '3G642A',
    '3G643A',
    '3G644A',
    '3G645A',
    '3G646A',
    '3G647A',
    '3G648A',
    '3H670A',
    '3H671A',
    '3H672A',
    '3H673A',
    '9D2X4A'
  ],
  entryId: 'blt8eba40fe95941ea0'
};

const LOCHSA_PRINTERS = {
  models: [
    '2Z618A',
    '2Z619A',
    '2Z620A',
    '2Z622A',
    '2Z623A',
    '2Z624A',
    '2Z625A',
    '2Z627A',
    '2Z628A',
    '2Z629A',
    '2Z630A',
    '2Z632A',
    '2Z633A',
    '2Z634A',
    '2Z635A',
    '2Z636A'
  ],
  entryId: 'blt8eba40fe95941ea0'
};

const LOTUS_PRINTERS = {
  models: [
    '8X3F4A',
    '8X3F0A',
    '8X3F2A',
    '8X3F6A',
    '8X3F8A',
    '8X3G0A',
    '8X3G4A',
    '8X3G5A',
    '8X3G6A',
    'B09S0A',
    'B09RYA',
    'B09RZA'
  ],
  entryId: 'blt04c822915a662ed4'
};

const CHERRY_2_4_PRINTERS = {
  models: ['8X3D6A', '8X3E0A', '8X3E7A'],
  entryId: 'blt04c822915a662ed4'
};

const ULYSSES_PRINTERS = {
  models: [
    '4RA85A',
    '4RA86A',
    '4RA87A',
    '4RA88A',
    '4RA89A',
    '5HH48A',
    '5HH49A',
    '5HH50A',
    '5HH51A',
    '5HH52A',
    '5HH53A',
    '5HH54A'
  ],
  entryId: 'blt31ac02e29d03268b'
};

// (FLOWERS)
const CHERRY_2_0_PRINTERS = {
  models: [
    '8X3D2A',
    '8X3D4A',
    '8X3D8A',
    '8X3E1A',
    '8X3E2A',
    '8X3E6A',
    '8X3E8A'
  ],
  entryId: 'bltbe7580e43fcd2a0b'
};

const EUTHENIA_PRINTERS = {
  models: [
    '499M7A',
    '499Q8A',
    '74T92A',
    '499Q5A',
    '74P27A',
    '499M8A',
    '759V2A',
    '499Q6A',
    '499M6A',
    '74P26A',
    '499Q3A',
    '759V1A',
    '499Q4A',
    '74P28A',
    '499Q7A',
    '759V0A'
  ],
  entryId: 'bltf954640540435a09'
};

const ZELUS_PRINTERS = {
  models: ['499Q9A', '499R0A', '499N4A', '74P25A'],
  entryId: 'blt2f6af9ac3272189d'
};

const YOSHINO_PRINTERS = {
  models: [
    '2Z599A',
    '2Z600A',
    '2Z601A',
    '2Z602A',
    '2Z604A',
    '2Z605A',
    '2Z606A',
    '2Z607A',
    '2Z609A',
    '2Z610A',
    '2Z611A',
    '2Z612A',
    '2Z613A',
    '2Z614A',
    '2Z615A'
  ],
  entryId: 'blt3f55ccc8dafdf990'
};

const STORM_PRINTERS = {
  models: [
    '3G649A',
    '3G650A',
    '3G651A',
    '3G652A',
    '3G653A',
    '3G654A',
    '3G655A',
    '3G656A',
    '3G657A',
    '3G658A',
    '3G659A',
    '3G660A',
    '3G661A',
    '3G662A',
    '3H667A',
    '3H668A'
  ],
  entryId: 'blt3f55ccc8dafdf990'
};

const NARITA_FB_PRINTERS = {
  models: [
    'B63JSA',
    'B63JTA',
    'B63JVB',
    'B63JWD',
    'B63JXD',
    'B63JYD',
    'B63JZA',
    'B63K0B',
    'B63K1A',
    'B6JW3B',
    'B6JW4D',
    'B6JW5D',
    'B6JW6D'
  ],
  entryId: 'blt2e4388e66c561112'
};

const NARITA_ADF_PRINTERS = {
  models: [
    'B63K3A',
    'B63K4A',
    'B63K5B',
    'B63K6D',
    'B63K7D',
    'B63K8D',
    'B63K9B',
    'B6JX3B',
    'B6JX4D',
    'B6JX5D',
    'B6JX6D'
  ],
  entryId: 'blt7c4f1c61437f94b7'
};

export const SUPPORTED_PRINTERS = [
  _3M_PRINTERS,
  TACCOLA_FB_PRINTERS,
  TACCOLA_ADF_PRINTERS,
  VASARI_FB_PRINTERS,
  VASARI_ADF_PRINTERS,
  SKYREACH_FB_PRINTERS,
  SKYREACH_ADF_PRINTERS,
  EYRIE_PRINTERS,
  BEAM_PRINTERS,
  JUPITER_PRINTERS,
  BEAM_MFP_PRINTERS,
  PIXIU_PRINTERS,
  TASSEL_PRINTERS,
  LEBI_PRINTERS,
  MARCONI_PRINTERS,
  MORETO_PRINTERS,
  VICTORIA_PRINTERS,
  KEBIN_PRINTERS,
  DRAGONFLY_BASE_PRINTERS,
  DRAGONFLY_WL_PRINTERS,
  DRAGONFLY_ADF_PRINTERS,
  TASSEL_BASE_PRINTERS,
  TRILLIUM_PRINTERS,
  SELENE_PRINTERS,
  HULK_PRINTERS,
  LOCHSA_PRINTERS,
  LOTUS_PRINTERS,
  CHERRY_2_4_PRINTERS,
  ULYSSES_PRINTERS,
  CHERRY_2_0_PRINTERS,
  EUTHENIA_PRINTERS,
  ZELUS_PRINTERS,
  YOSHINO_PRINTERS,
  STORM_PRINTERS,
  NARITA_FB_PRINTERS,
  NARITA_ADF_PRINTERS
];
