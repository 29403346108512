import { useCallback } from 'react';
import { useApiCall, usePreferencesContext } from '@jarvis/react-portal-addons';
import { ApiCallResponseType } from 'src/types/commonTypes';
import { apiCallback } from './baseAPICall';

export type PrinterListingPagesType = {
  url: string;
};

type UsePrinterListingPagesResponseType = {
  data: {
    entries: {
      support_headline: string;
      select_printer_model: string;
      not_my_printer_button_text: string;
      support_headline_mobile: string;
      support_subhead: string;
      support_subhead_mobile: string;
      continue_button_text: string;
      support_graphic: PrinterListingPagesType;
      back_button_text: string;
    }[];
  } | null;
} & ApiCallResponseType;

export default function usePrinterListingPages(): UsePrinterListingPagesResponseType {
  const config = usePreferencesContext();

  const apiCall = useCallback(async () => {
    const apiName = 'printer-listing-pages';
    return await apiCallback({ apiName, config });
  }, [config]);

  return useApiCall({ apiCall });
}
