import en_US from './en_US.json';
import pt_BR from './pt_BR.json';

// This is the list of all available locales that your MFE supports
// You reuse the same locale file for multiple locales if they share the same translations

export default {
  en_US,
  pt_BR,
  pt_PT: pt_BR
};
