import React from 'react';
import { Stepper as VeneerStepper } from '@veneer/core';
import { useScreenSizes } from 'src/hooks/useFixedScreenSizes';
import * as S from './styles';

export enum StepperAppearance {
  STANDARD = 'standard',
  COMPACT = 'compact',
  INDICATOR = 'indicator'
}

export const Stepper = ({
  stepItems,
  handleStepperClick
}: {
  stepItems: {
    description?: React.ReactNode;
    status?: 'complete' | 'current' | 'disabled' | 'incomplete';
    step?: string;
    label?: string;
  }[];
  handleStepperClick: (event?: React.SyntheticEvent, index?: number) => void;
}): React.ReactElement => {
  const { isSmall, isMedium } = useScreenSizes();

  return (
    <S.StepperContainer>
      <VeneerStepper
        data-testid="device-setup-stepper"
        appearance={
          isSmall
            ? StepperAppearance.INDICATOR
            : isMedium
              ? StepperAppearance.COMPACT
              : StepperAppearance.STANDARD
        }
        interactive
        onChange={handleStepperClick}
        steps={stepItems}
      />
    </S.StepperContainer>
  );
};
