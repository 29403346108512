export const rgba = (color: string, alpha: number): string => {
  const hexToRgb = (hex: string) => {
    let r = 0,
      g = 0,
      b = 0;

    r = parseInt(hex[1] + hex[2], 16);
    g = parseInt(hex[3] + hex[4], 16);
    b = parseInt(hex[5] + hex[6], 16);
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  };

  return hexToRgb(color);
};
