import styled from 'styled-components';
import { breakpoints } from 'src/constants/breakpoints';
import primitives from '@veneer/primitives';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  max-width: 64rem;
  margin: 0 auto;
  padding: 1rem 2.188rem;
`;

export const Title = styled.h1<{ isRTL?: boolean }>`
  color: #4e4e4e;
  direction: ${(props) => (props.isRTL ? 'rtl' : 'ltr')};
  padding-top: 1rem;

  @media only screen and (max-width: ${breakpoints.tablet}) {
    display: flex;
    justify-content: center;
  }
`;

export const ContainerGraphic = styled.div`
  display: flex;
  padding-top: 5vw;
  gap: 5rem;

  img {
    min-width: 13.75rem;
    align-self: center;
    padding-bottom: 0.8rem;
    text-align: center;

    &.hpx {
      filter: hue-rotate(46deg);
    }
  }

  @media only screen and (max-width: ${breakpoints.tablet}) {
    flex-direction: column;
    align-items: center;
    padding-top: 0.625rem;
    gap: 1rem;
  }
`;

export const ContainerBody = styled.div`
  max-width: 44.5rem;
  color: #4e4e4e;

  p {
    margin: 1rem 0;
    > a {
      color: #027aae;
      :hover {
        color: #006691;
      }
      :active {
        color: #004f74;
      }
    }
  }

  &.hpx {
    a {
      color: ${primitives.color.royalBlue7};
      :hover {
        color: ${primitives.color.royalBlue8};
      }
      :active {
        color: ${primitives.color.royalBlue9};
      }
    }
  }

  @media only screen and (max-width: ${breakpoints.tablet}) {
    text-align: center;
    max-width: 34.5rem;
  }
`;

export const ContainerButton = styled.div<{ isRTL?: boolean }>`
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  padding-top: 8rem;
  direction: ${(props) => (props.isRTL ? 'rtl' : 'ltr')};

  &.hpx {
    justify-content: space-between;

    @media only screen and (max-width: ${breakpoints.tablet}) {
      padding-top: 1rem;
      display: flex;
      flex-direction: column-reverse;
      justify-content: center;
      gap: 0.625rem;
      margin-bottom: 1rem;
    }
  }

  &.hpsmart {
    @media only screen and (max-width: ${breakpoints.tablet}) {
      padding-top: 1rem;
      display: flex;
      flex-direction: column-reverse;
      justify-content: center;
      align-items: center;
      gap: 0.625rem;
      margin-bottom: 1rem;
    }
  }
`;

export const GroupButton = styled.div`
  display: flex;
  gap: 1rem;

  @media only screen and (max-width: ${breakpoints.tablet}) {
    display: flex;
    flex-direction: column-reverse;
    gap: 0.625rem;
  }
`;
