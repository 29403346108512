import styled from 'styled-components';
import primitives from '@veneer/primitives';
import { breakpoints } from '../../constants/breakpoints';
import * as V from '@veneer/core';

export const StyledList = styled(V.List)`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  border-radius: 0px !important;
  margin: 8px 0;

  &.hpsmart {
    @media only screen and (min-width: ${breakpoints.tablet}) {
      padding-top: 1rem;
      flex-direction: row;
      flex-wrap: row;
    }
  }
`;

export const StyledListItem = styled(V.ListItem)`
  border-radius: 0px !important;
  margin: 0;
  flex: 0 0 31%;

  &.hpx {
    &:hover,
    &:focus,
    &:active {
      background-color: ${primitives.color.royalBlue0} !important;
    }
  }

  &.hpsmart {
    padding: 20px 0;
    min-height: 80px;

    @media only screen and (min-width: ${breakpoints.tablet}) {
      padding: 12px;
      border: 1px solid !important;
      border-radius: 12px !important;
      margin: 5px !important;
    }
  }
`;

export const Graphic = styled.img`
  width: 1.563rem;
  height: 1.563rem;
  &.hpx {
    width: 60px !important;
    height: 60px !important;
  }
`;

export const CenterArea = styled.div`
  display: inline-grid;
  @media screen and (min-width: ${breakpoints.tablet}) {
    &.hpx .label-small {
      color: ${primitives.color.royalBlue7};
    }
  }
`;

export const ButtonGroup = styled(V.ButtonGroup)`
  &.hpx {
    width: 100%;
    justify-content: end;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  max-height: auto;
  min-height: auto;
  overflow-y: hidden;
  box-sizing: border-box;
  max-width: 90vw;
  padding-top: 1rem;
  padding-bottom: 0;
  margin: 0 5vw;

  @media screen and (min-width: ${breakpoints.mobile}) {
    margin: auto;
  }

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin-bottom: 35vw;
    overflow: hidden;
  }

  @media screen and (min-width: ${breakpoints.wide}) {
    max-width: 64rem;
    margin-bottom: 0;
    min-height: 0;
  }
`;

export const Title = styled.h4`
  color: #313131;
  @media screen and (min-width: ${breakpoints.tablet}) {
    &.hpx {
      width: 100%;
    }
  }
`;
