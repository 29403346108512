import React from 'react';
import useUpgradePage from 'src/hooks/useUpgradePage';
import { LoadingPage } from '../LoadingPage';
import * as S from './styles';
import * as V from '@veneer/core';
import useMultiUiBrandContext from 'src/contexts/MultiUiBrand/useMultiUiBrandContext';
import useAppBadges from 'src/hooks/useAppBadges';

const UpgradePage: React.FC = () => {
  const { uiBrand } = useMultiUiBrandContext();

  const infoUpgradePage = useUpgradePage();
  const appBadges = useAppBadges();
  const {
    hpsmart_heading,
    upgrade_headline,
    upgrade_os_message,
    button_text,
    button_destination
  } = infoUpgradePage.data?.entries[0] || {};

  const { landing_page_app_badges } = appBadges.data?.entries[0] || {};
  const microsoftBadgeData = landing_page_app_badges?.[2]?.app_badge;
  const macBadgeData = landing_page_app_badges?.[3]?.app_badge;

  if (infoUpgradePage?.pending) {
    return <LoadingPage />;
  }

  return (
    <S.Container>
      <S.Brand>
        <V.LogoHp
          appearance="multicolor"
          size={40}
        />
        <S.TextLogo className="subtitle-regular">{hpsmart_heading}</S.TextLogo>
      </S.Brand>

      <S.Title
        className="subtitle-large"
        dangerouslySetInnerHTML={{ __html: upgrade_headline }}
      />
      <S.SubTitle
        className="subtitle-regular"
        dangerouslySetInnerHTML={{ __html: upgrade_os_message }}
      />
      <S.ContainerButton>
        <V.Button
          id="find-all-printers"
          appearance="primary"
          onClick={async () => {
            window.location.assign(button_destination);
          }}
          className={uiBrand}
        >
          {button_text}
        </V.Button>
      </S.ContainerButton>
      <S.ContainerAppBadges>
        <img
          alt={microsoftBadgeData?.app_badge_graphic?.title}
          src={microsoftBadgeData?.app_badge_graphic?.url}
          className="microsoft"
        />
        <img
          alt={macBadgeData?.app_badge_graphic?.title}
          src={macBadgeData?.app_badge_graphic?.url}
        />
      </S.ContainerAppBadges>
    </S.Container>
  );
};

export default UpgradePage;
