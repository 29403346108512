import { SUPPORTED_PRINTERS } from '../constants/printerDetails';

/**
 * Check if the printer model is supported by beacon reset
 * The logic for this should basically copy from hpc-frontend\src\constants\printerConnection.js
 * and hpc-frontend\src\constants\contentStackResources.js, centralizing the list of supported
 * models in BEACON_RESET_SUPPORTED_SKUS
 * @param model string
 * @returns boolean
 */
export function isSupported(model: string): boolean {
  return SUPPORTED_PRINTERS.some((item) =>
    item.models?.includes(model?.toUpperCase())
  );
}

/**
 * Get the content stack entry id corresponding to the printer model
 * The logic for this should basically copy from hpc-frontend\src\constants\printerConnection.js
 * and hpc-frontend\src\constants\contentStackResources.js, centralizing model -> entry id mapping
 * into SKU_TO_ENTRY_ID_MAPPING
 * @param model string
 * @returns string
 */
export function getEntryId(model: string): string {
  const entry = SUPPORTED_PRINTERS.find((item) =>
    item.models?.includes(model?.toUpperCase())
  );
  return entry?.entryId || null;
}
