import React from 'react';
import * as S from './styles';
import * as T from './types';
import * as V from '@veneer/core';
import printerSVG from 'src/assets/images/printer.svg';
import printerHpxSVG from 'src/assets/images/printer-hpx.svg';
import useMultiUiBrandContext from 'src/contexts/MultiUiBrand/useMultiUiBrandContext';
import { UiTheme } from 'src/config/constants';
import usePrinterListingPages from 'src/hooks/usePrinterListingPages';
import { DEEEPLINKS } from 'src/constants/deeplinks';
import { LoadingPage } from '../LoadingPage';

const DeviceList: React.FC<T.DeviceListPropsType> = ({ devices }) => {
  const { uiBrand } = useMultiUiBrandContext();
  const isHpx = uiBrand === UiTheme.hpx;

  const plp = usePrinterListingPages();

  const {
    support_headline = "Let's connect your printer",
    select_printer_model = 'Select printer model',
    not_my_printer_button_text = 'Printer not listed'
  } = plp.data?.entries?.[0] || {};

  const onClick = (href: string) => {
    window.Shell.v1.navigation.push(href + '?showChangeButton=true');
  };

  const onKey = (e: React.KeyboardEvent, href: string) => {
    if (e.key === 'Enter') {
      onClick(href);
    }
  };

  if (devices) {
    const items = devices.map((item, index) => {
      const dataTestIdBaseName = `list-page-printer-card-${index + 1}`;
      return (
        <S.StyledListItem
          data-testid={dataTestIdBaseName}
          role={'button'}
          tabIndex={0}
          className={uiBrand}
          key={index}
          onClick={() => onClick(item.href)}
          onKeyUp={(e) => onKey(e, item.href)}
          leadingArea={
            <S.Graphic
              data-testid={`${dataTestIdBaseName}-image`}
              id="graphic"
              src={isHpx ? printerHpxSVG : printerSVG}
              alt="Printer"
              className={uiBrand}
            />
          }
          centerArea={
            <S.CenterArea className={uiBrand}>
              <div
                data-testid={`${dataTestIdBaseName}-printer-name`}
                className="label-regular"
              >
                {item.modelName}
              </div>
              <div
                data-testid={`${dataTestIdBaseName}-select-printer-model`}
                className="label-small"
              >
                {select_printer_model}
              </div>
            </S.CenterArea>
          }
          trailingArea={<span />}
        />
      );
    });

    if (plp.pending) {
      return <LoadingPage />;
    }

    return (
      <S.Container>
        <S.Title
          data-testid="list-page-title"
          className={uiBrand}
        >
          {support_headline}
        </S.Title>
        <S.StyledList
          className={uiBrand}
          id="list-of-beacon-devices"
        >
          {items}
        </S.StyledList>
        <S.ButtonGroup className={uiBrand}>
          <V.Button
            data-testid="list-page-printer-not-listed-button"
            onClick={() => {
              window.Shell.v1.navigation.push(DEEEPLINKS.SUPPORT_GENERIC);
            }}
            appearance={isHpx ? 'secondary' : 'ghost'}
            id="generic-support-printer-not-found"
          >
            {not_my_printer_button_text}
          </V.Button>
        </S.ButtonGroup>
      </S.Container>
    );
  }

  return <S.Container data-testid="device-list-empty"></S.Container>;
};

export default DeviceList;
