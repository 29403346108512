import styled from 'styled-components';
import { Button } from '@veneer/core';
import { breakpoints } from '../../../constants/breakpoints';

export const Container = styled.div<{ isHpx: boolean; isRTL: boolean }>`
  display: flex;
  justify-content: ${({ isHpx }) => (isHpx ? 'flex-end' : 'space-between')};
  margin-top: 40px;

  @media only screen and (max-width: ${breakpoints.bigtablet}) {
    flex-direction: column-reverse;
    align-items: center;
  }

  ${({ isHpx, isRTL }) =>
    isHpx &&
    `
      button:nth-of-type(2) {
        margin-left: ${isRTL ? '0' : '16px'};
        margin-right: ${isRTL ? '16px' : '0'};
      }

      @media only screen and (max-width: ${breakpoints.smalldesktop}) {
        flex: 1;
        justify-content: flex-start;
        padding-bottom: 30px;
        align-items: initial;
      }
  `}
`;

export const FooterButton = styled(Button)`
  @media only screen and (max-width: ${breakpoints.bigtablet}) {
    margin: 10px 0 !important;
  }
`;

export const FooterButtonText = styled.span``;
