import { useEffect, useState } from 'react';

type ScreenSizes = {
  isSmall: boolean; // <= 500
  isMedium: boolean; // <= 720
};

export const useScreenSizes = (): ScreenSizes => {
  const [screenSizes, setScreenSizes] = useState<ScreenSizes>({
    isSmall: window.innerWidth <= 500,
    isMedium: window.innerWidth <= 720
  });

  useEffect(() => {
    const handleResize = () => {
      setScreenSizes({
        isSmall: window.innerWidth <= 500,
        isMedium: window.innerWidth > 500 && window.innerWidth <= 720
      });
    };

    window.addEventListener('resize', handleResize);

    // Cleanup listener on unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return screenSizes;
};
