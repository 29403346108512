import { useCallback } from 'react';
import { apiCallback } from './baseAPICall';
import { ApiCallResponseType } from 'src/types/commonTypes';
import { useApiCall, usePreferencesContext } from '@jarvis/react-portal-addons';

type BadegesType = {
  app_badge: {
    app_badge_graphic: {
      url: string;
      title: string;
    };
  };
};

type AppBadgesType = {
  data: {
    entries: {
      landing_page_app_badges: BadegesType[];
    }[];
  } | null;
} & ApiCallResponseType;

export default function useAppBadges(): AppBadgesType {
  const config = usePreferencesContext();

  const apiCall = useCallback(async () => {
    const apiName = 'app-badges';
    return await apiCallback({ apiName, config });
  }, [config]);

  return useApiCall({ apiCall });
}
