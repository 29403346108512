import React, { useEffect, useMemo, useState, useContext } from 'react';
import useMultiUiBrandContext from 'src/contexts/MultiUiBrand/useMultiUiBrandContext';
import { useDeviceSetup } from 'src/hooks/useDeviceSetup';
import { useParams, Redirect } from 'react-router-dom';
import { isSupported, getEntryId } from 'src/utils/printerSupport';
import { LoadingPage } from '../LoadingPage';
import { Stepper } from './Stepper';
import {
  IconCheckmark as VennerIconCheckmark,
  DirectionContext
} from '@veneer/core';
import { CustomCarousel } from './Carousel';
import { Buttons } from './Buttons';
import { UiTheme } from 'src/config/constants';
import { DEEEPLINKS } from 'src/constants/deeplinks';
import * as T from './types';
import * as S from './styles';

const IconCheckmark = <VennerIconCheckmark filled />;

enum Status {
  COMPLETE = 'complete',
  INCOMPLETE = 'incomplete',
  CURRENT = 'current'
}

export const DeviceSetup: React.FC<T.DeviceSetupPropsType> = ({
  devices,
  deviceId,
  platform
}) => {
  const { uiBrand } = useMultiUiBrandContext();
  const isHpx = uiBrand === UiTheme.hpx;

  const useDirectionContext = () => useContext(DirectionContext);
  const { isRTL } = useDirectionContext();

  const [stepItems, setStepItems] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  const { model } = useParams();

  const deviceSetup = useDeviceSetup();

  const modelIsSupported = useMemo(() => model && isSupported(model), [model]);

  useEffect(() => {
    if (modelIsSupported) {
      deviceSetup.makeApiCall({ entryId: getEntryId(model) });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [model, modelIsSupported]);

  useEffect(() => {
    const csData = deviceSetup?.data?.entry;

    if (csData) {
      setStepItems(
        csData.support_steps.map((step, index) => {
          return {
            status: index === 0 ? Status.COMPLETE : Status.INCOMPLETE,
            step: index === 0 ? IconCheckmark : (index + 1).toString()
          };
        })
      );
    }
  }, [deviceSetup?.data?.entry]);

  const handleStepperClick = (event?: React.SyntheticEvent, index?: number) => {
    const newStepItems = stepItems.map((item, i) => {
      if (i === index) {
        return {
          ...item,
          status: Status.CURRENT
        };
      } else if (i === currentIndex) {
        return {
          ...item,
          status: Status.COMPLETE,
          step: IconCheckmark
        };
      }

      return item;
    });

    setCurrentIndex(index);
    setStepItems(newStepItems);
  };

  const handleRightClick = (nextSlide?: () => void) => {
    const newStepItems = stepItems.map((item, i) => {
      if (i === currentIndex + 1) {
        return {
          ...item,
          status: Status.CURRENT
        };
      } else if (i === currentIndex) {
        return {
          ...item,
          status: Status.COMPLETE,
          step: IconCheckmark
        };
      }

      return item;
    });

    setCurrentIndex(
      currentIndex < stepItems.length - 1
        ? currentIndex + 1
        : stepItems.length - 1
    );
    setStepItems(newStepItems);

    if (nextSlide) {
      nextSlide();
    }
  };

  const handleLeftClick = (prevSlide?: () => void) => {
    const newStepItems = stepItems.map((item, i) => {
      if (i === currentIndex - 1) {
        return {
          ...item,
          status: Status.CURRENT
        };
      } else if (i === currentIndex) {
        return {
          ...item,
          status: Status.COMPLETE,
          step: IconCheckmark
        };
      }

      return item;
    });

    setCurrentIndex(currentIndex > 0 ? currentIndex - 1 : 0);
    setStepItems(newStepItems);

    if (prevSlide) {
      prevSlide();
    }
  };

  if (!modelIsSupported) {
    if (devices?.length === 1) {
      return <Redirect to={devices[0].href} />;
    }

    if (devices?.length > 1 && deviceId) {
      return (
        <Redirect to={`${DEEEPLINKS.SUPPORT_LIST}?deviceid=${deviceId}`} />
      );
    }

    return (
      <Redirect to={`${DEEEPLINKS.SUPPORT_GENERIC}?platform=${platform}`} />
    );
  }

  if (deviceSetup.error) {
    return (
      <Redirect to={`${DEEEPLINKS.SUPPORT_GENERIC}?platform=${platform}`} />
    );
  }

  if (deviceSetup.pending) {
    return <LoadingPage />;
  }

  const {
    support_headline = '',
    support_subhead = '',
    change_printer_button_text = '',
    continue_button_text = ''
  } = deviceSetup?.data?.entry || {};

  return (
    <S.Container
      isHpx={isHpx}
      data-testid="printer-connection-container"
    >
      <S.Title
        data-testid="printer-connection-title"
        className="title-small"
        dangerouslySetInnerHTML={{ __html: support_headline }}
      />

      {support_subhead && (
        <S.SubTitle
          data-testid="printer-connection-subtitle"
          dangerouslySetInnerHTML={{ __html: support_subhead }}
        />
      )}

      <S.StepContainer>
        <Stepper
          stepItems={stepItems}
          handleStepperClick={handleStepperClick}
        />

        <CustomCarousel
          currentIndex={currentIndex}
          handleRightClick={handleRightClick}
          handleLeftClick={handleLeftClick}
          support_steps={deviceSetup?.data?.entry?.support_steps}
          isHpx={isHpx}
          isRTL={isRTL}
        />
      </S.StepContainer>

      <Buttons
        change_printer_button_text={change_printer_button_text}
        continue_button_text={continue_button_text}
        currentIndex={currentIndex}
        stepsLength={stepItems.length}
        platform={platform}
        isHpx={isHpx}
        isRTL={isRTL}
      />
    </S.Container>
  );
};

export default DeviceSetup;
