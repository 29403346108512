import { useApiCall, usePreferencesContext } from '@jarvis/react-portal-addons';
import { useCallback } from 'react';
import { apiCallback } from './baseAPICall';
import { ApiCallResponseType } from 'src/types/commonTypes';

export type ButtonType = {
  buttons_group: {
    primary_button_text: string;
    secondary_button_text: string;
    tertiary_button_text: string;
  };
};

type GenericType = {
  data: {
    entries: {
      generic_buttons: [ButtonType];
      support_body: string;
      support_graphic: {
        title: string;
        url: string;
      };
      support_graphic_alt: {
        title: string;
        url: string;
      };
      support_headline: string;
    }[];
  } | null;
} & ApiCallResponseType;

export default function useGeneric(): GenericType {
  const config = usePreferencesContext();
  const apiCall = useCallback(async () => {
    const apiName = 'generic';
    return await apiCallback({ apiName, config });
  }, [config]);

  return useApiCall({ apiCall });
}
