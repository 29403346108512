import { ApiCallbackType, ContentConfigType } from 'src/types/commonTypes';

const getContent = async ({
  locale,
  config,
  entryId,
  apiName
}: ContentConfigType): Promise<Response> => {
  const api = config?.apis[apiName];
  const url =
    `${api}?environment=${config.environment}&locale=${locale}`.replace(
      '<ENTRYID>',
      entryId
    );

  return await fetch(url, {
    headers: {
      api_key: config.apiKey,
      access_token: config.accessToken,
      'Content-Type': 'application/json'
    }
  });
};

export const apiCallback = async ({
  entryId,
  apiName,
  config
}: ApiCallbackType): Promise<any> => {
  const locale = window?.Shell?.v1?.localization?.locale?.toLowerCase();
  const response = await getContent({ locale, config, entryId, apiName });

  if (response.ok) {
    return response.json();
  }

  if (response.status === 422) {
    const enusResponse = await getContent({
      locale: 'en-us',
      config,
      entryId,
      apiName
    });
    return enusResponse.json();
  }
};
