import { useCallback } from 'react';
import { useApiCall, usePreferencesContext } from '@jarvis/react-portal-addons';
import { ApiCallResponseType } from 'src/types/commonTypes';
import { apiCallback } from './baseAPICall';

export type SelectListType = {
  supported_printer_names: {
    supported_names: {
      printer_id: string;
      printer_name: string;
    };
  }[];
  slug: string;
};

type DeviceSelectListType = {
  data: {
    entries: SelectListType[];
  } | null;
} & ApiCallResponseType;

export default function useDeviceSelectList(): DeviceSelectListType {
  const config = usePreferencesContext();

  const apiCall = useCallback(async () => {
    const apiName = 'device-select-list';
    return await apiCallback({ apiName, config });
  }, [config]);

  return useApiCall({ apiCall });
}
