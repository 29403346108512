import React, { useContext, useMemo, useState } from 'react';
import * as S from './styles';
import * as V from '@veneer/core';
import { isMobile } from 'react-device-detect';
import useDeviceSelectList from 'src/hooks/useDeviceSelectList';
import usePrinterListingPages from 'src/hooks/usePrinterListingPages';
import useMultiUiBrandContext from 'src/contexts/MultiUiBrand/useMultiUiBrandContext';
import { UiTheme } from 'src/config/constants';
import { LoadingPage } from '../LoadingPage';
import { DEEEPLINKS } from 'src/constants/deeplinks';
import { supportImages } from 'src/assets/support';

type SelectedPrinterType = {
  value: string[];
  url: string[];
  enable: boolean;
};

type DeviceSelectListPropsType = {
  value: string;
  label: string;
  printerUrl?: string;
};

type ExtendedSelectOption = V.SelectOption<string> & { printerUrl?: string };

const DeviceSelectList: React.FC = () => {
  const { uiBrand } = useMultiUiBrandContext();
  const isHpx = uiBrand === UiTheme.hpx;
  const selectList = useDeviceSelectList();
  const printerPageInfo = usePrinterListingPages();

  const useDirectionContext = () => useContext(V.DirectionContext);
  const { isRTL } = useDirectionContext();

  const [selectedPrinter, setSelectedPrinter] = useState<SelectedPrinterType>({
    value: [],
    url: [],
    enable: true
  });

  const {
    support_headline,
    support_headline_mobile,
    support_subhead,
    support_subhead_mobile,
    select_printer_model,
    continue_button_text,
    support_graphic,
    back_button_text
  } = printerPageInfo?.data?.entries?.[0] || {};

  const getList = useMemo(() => {
    const newList: DeviceSelectListPropsType[] = [];

    selectList?.data?.entries?.map((entry) => {
      entry?.supported_printer_names?.forEach((info) => {
        newList.push({
          value: info?.supported_names?.printer_id,
          label: info?.supported_names?.printer_name,
          printerUrl: entry?.slug
        });
      });
    });

    return newList;
  }, [selectList?.data?.entries]);

  const sortByLabel = (arr: DeviceSelectListPropsType[]) => {
    return arr.sort((a, b) => {
      if (!a?.label) return 1;
      if (!b?.label) return -1;
      return a?.label?.localeCompare(b.label, 'en', { sensitivity: 'base' });
    });
  };

  const handleChange = (e: ExtendedSelectOption) => {
    setSelectedPrinter((prev) => {
      return {
        ...prev,
        value: [e?.value],
        url: [e?.printerUrl],
        enable: false
      };
    });
  };

  const handleClear = () => {
    setSelectedPrinter((prev) => {
      return {
        ...prev,
        value: [],
        url: [],
        enable: true
      };
    });
  };

  if (printerPageInfo?.pending) {
    return <LoadingPage />;
  }

  const selectPrinterHpSmart = (
    <div>
      <S.SubTitle
        data-testid="printer-list-subtitle"
        isRTL={isRTL}
      >
        {isMobile ? support_subhead_mobile : support_subhead}
      </S.SubTitle>
      <S.Content className={uiBrand}>
        <img
          data-testid="printer-list-printer-image"
          className={uiBrand}
          alt={support_headline}
          src={support_graphic?.url}
          role="img"
        />
        <S.ContainerSelect>
          <S.LabelSelectPrinterModel>
            {select_printer_model}
          </S.LabelSelectPrinterModel>
          <V.Select
            id="select-usage"
            data-testid="printer-list-select"
            options={sortByLabel(getList)}
            label={select_printer_model}
            placement="bottom"
            helperTextVisibility="auto"
            onChange={(e) => handleChange(e)}
            onClear={() => handleClear()}
            value={selectedPrinter?.value}
            visibleOptions={5}
            className={uiBrand}
          />
        </S.ContainerSelect>
      </S.Content>
    </div>
  );

  const selectPrinterHpx = (
    <div>
      <S.Content className={uiBrand}>
        <S.ContainerImageHpx>
          <img
            data-testid="printer-list-printer-image"
            className={uiBrand}
            alt={support_headline}
            src={supportImages['/assets/support/generic_printer_hpx.svg']}
            role="img"
          />
        </S.ContainerImageHpx>

        <S.ContainerSelect>
          <S.SubTitle
            data-testid="printer-list-subtitle"
            className={uiBrand}
            isRTL={isRTL}
          >
            {isMobile ? support_subhead_mobile : support_subhead}
          </S.SubTitle>
          <V.Select
            id="select-usage"
            data-testid="printer-list-select"
            options={sortByLabel(getList)}
            label={select_printer_model}
            placement="bottom"
            helperTextVisibility="auto"
            onChange={(e) => handleChange(e)}
            onClear={() => handleClear()}
            value={selectedPrinter?.value}
            visibleOptions={5}
            className={uiBrand}
          />
        </S.ContainerSelect>
      </S.Content>
    </div>
  );

  return (
    <S.Container>
      <S.Title
        data-testid="printer-list-title"
        className={`${uiBrand} title-small`}
        isRTL={isRTL}
      >
        {isMobile ? support_headline_mobile : support_headline}
      </S.Title>

      <S.SectionPrinter>
        {isHpx ? selectPrinterHpx : selectPrinterHpSmart}

        <S.Footer className={uiBrand}>
          <V.Button
            data-testid="printer-list-back-button"
            className={`${uiBrand} backButton`}
            appearance={isHpx ? 'ghost' : 'secondary'}
            onClick={() => window.Shell.v1.navigation.goBack()}
          >
            {back_button_text || 'Back'}
          </V.Button>
          <V.Button
            data-testid="printer-list-continue-button"
            className={`${uiBrand} buttonContinue`}
            disabled={selectedPrinter?.enable}
            onClick={() => {
              window.Shell.v1.navigation.push(
                `${DEEEPLINKS.SUPPORT_PRINTER_CONNECTION}/${selectedPrinter?.url[0]}?showChangeButton=true`
              );
            }}
          >
            {continue_button_text}
          </V.Button>
        </S.Footer>
      </S.SectionPrinter>
    </S.Container>
  );
};

export default DeviceSelectList;
