import { breakpoints } from 'src/constants/breakpoints';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  min-height: calc(100vh - 10vh);
  margin: 0 auto;

  @media only screen and (max-width: ${breakpoints.smalldesktop}) {
    padding: 1.25rem 0.25rem;
  }

  @media screen and (min-width: ${breakpoints.wide}) {
    font-size: 0.875rem;
    max-width: 64rem;
    padding: 4vh;
    margin-bottom: 0;
  }
`;

export const Title = styled.h4<{ isRTL: boolean }>`
  color: #313131;
  direction: ${(props) => (props.isRTL ? 'rtl' : 'ltr')};
  @media screen and (min-width: ${breakpoints.smalldesktop}) {
    direction: ${(props) => (props.isRTL ? 'rtl' : 'ltr')};
  }

  @media screen and (max-width: ${breakpoints.bigmobile}) {
    display: flex;
    justify-content: center;
  }
`;

export const SubTitle = styled.h6<{ isRTL: boolean }>`
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  direction: ${(props) => (props.isRTL ? 'rtl' : 'ltr')};
  color: #4e4e4e;
  line-height: 1.33;

  &.hpx {
    color: #525365;
    margin-bottom: 1.563rem;
  }
`;

export const Content = styled.div`
  display: flex;

  &.hpx {
    height: 60vh;
    flex-direction: column;

    @media only screen and (min-width: ${breakpoints.tablet}) {
      margin-right: 1.625rem;
      flex-direction: row;
    }
  }

  img {
    margin-right: 7.5rem;
    &.hpsmart {
      display: none;
      @media only screen and (min-width: ${breakpoints.medium}) {
        display: flex;
      }
    }
  }

  @media only screen and (min-width: ${breakpoints.tablet}) {
    padding: 2.5rem 0;
  }
`;

export const ContainerImageHpx = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 18.75rem;
    margin-right: 5rem;
    margin-left: 5rem;

    @media only screen and (max-width: ${breakpoints.medium}) {
      width: 16rem;
      margin-right: 3.125rem;
      margin-left: 3.125rem;
    }

    @media only screen and (max-width: ${breakpoints.tablet}) {
      margin: 1.563rem 0 1.563rem 0;
    }
  }
`;

export const ContainerSelect = styled.div`
  width: 100%;
`;

export const LabelSelectPrinterModel = styled.p`
  margin-left: 0.25rem;
  font-size: 1rem;
  margin-bottom: 0rem;
  text-align: left;
  color: #404040;
`;

export const Footer = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 95%;
  margin: 1.563rem;
  box-sizing: border-box;

  &.hpsmart {
    @media screen and (max-width: ${breakpoints.bigtablet}) {
      justify-content: center;
      .backButton {
        display: none;
      }
    }
  }

  &.hpx {
    @media only screen and (max-width: ${breakpoints.smalldesktop}) {
      margin: 0;
      width: 100%;
      flex-direction: column;

      .buttonContinue {
        margin-top: 0.938rem;
        order: -1;
      }
    }
  }
`;

export const SectionPrinter = styled.div`
  display: flex;
  flex-direction: column;
  flex: 2;
  justify-content: space-between;
`;
