import React from 'react';
import { DEEEPLINKS } from 'src/constants/deeplinks';
import * as S from './styles';

export const Buttons = ({
  change_printer_button_text,
  continue_button_text,
  currentIndex,
  stepsLength,
  platform,
  isHpx,
  isRTL
}: {
  change_printer_button_text: string;
  continue_button_text: string;
  currentIndex: number;
  stepsLength: number;
  platform: string;
  isHpx: boolean;
  isRTL: boolean;
}): React.ReactElement => {
  const searchParams = new URLSearchParams(window.location.search);
  const continueButtonURL =
    searchParams.get('primary') || 'hpsmart://reset/continue';
  const isListAvailable = searchParams.get('showChangeButton') === 'true';

  return (
    <S.Container
      isHpx={isHpx}
      isRTL={isRTL}
    >
      <S.FooterButton
        id="change-printer-button"
        data-testid="printer-connection-change-printer-button"
        appearance="secondary"
        small
        onClick={() => {
          isListAvailable
            ? window.Shell.v1.navigation.goBack()
            : window.Shell.v1.navigation.push(
                `${DEEEPLINKS.SUPPORT_GENERIC}?platform=${platform}`
              );
        }}
      >
        <S.FooterButtonText
          dangerouslySetInnerHTML={{ __html: change_printer_button_text }}
        />
      </S.FooterButton>

      <S.FooterButton
        id="continue-button"
        data-testid="printer-connection-continue-button"
        small
        disabled={currentIndex === stepsLength - 1 ? false : true}
        onClick={() => window.location.assign(continueButtonURL)}
      >
        <S.FooterButtonText
          dangerouslySetInnerHTML={{ __html: continue_button_text }}
        />
      </S.FooterButton>
    </S.Container>
  );
};
