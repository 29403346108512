import { ConfigType } from 'src/types/commonTypes';

export const UiTheme = {
  hpsmart: 'hpsmart',
  hpx: 'hpx'
};

export const DefaultPreferences: ConfigType = {
  apiKey: 'bltcad87795ce18c6ce',
  accessToken: 'cs8e7a3b88dc78dbe7eb302cbe',
  environment: 'dev',
  apis: {
    'printer-listing-pages':
      'https://www.hpsmartdev.com/content/v3/content_types/printer_listing_pages/entries'
  }
};
